import React, { FC, useContext } from "react";
import { SerializedItem } from "~/foundation/Jss";
import { DocumentListItem, DocumentListSection } from "../generated-types";
import {
	AccordionButton, AccordionItem, AccordionPanel, Box, Heading, Text as ChakraText
} from "@chakra-ui/react";
import { Icon } from "~/foundation/Components/Icon";
import { DocumentListDownload } from "./DocumentListDownload";
import { useTranslation } from "~/foundation/Dictionary";
import { GlobalDictionary } from "~/foundation/Dictionary/dictionary";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";

type RenderDocumentListSectionProps = {
	item: SerializedItem<DocumentListSection, DocumentListItem>,
	isAccordion: boolean,
	children?: React.ReactNode,
	noBg: boolean,
	compactVersion?: boolean,
}

export const RenderDocumentListSection: FC<RenderDocumentListSectionProps> = ({ item, isAccordion, children, noBg, compactVersion }) => {
	const [t] = useTranslation<GlobalDictionary>();
	const assetCount = item.children.filter(documentItem => documentItem.fields?.asset?.value?.href).length;
	const [theme] = useContext(ColorThemeContext);

	if (assetCount === 0){
		return <></>
	}

	const downloadAvailable = item.children.some(documentItem => documentItem.fields?.asset?.value.isMediaLink);

	const trackingAttributes = {
		"data-tracking-type": "visibility",
		"data-tracking-id": "documents_list",
		"data-tracking-data": JSON.stringify({
			type: "documents list section",
			text: item.fields?.title?.value,
			group: item.fields?.title?.value,
		})
	};

	return (
		<>
			{isAccordion ?
				<AccordionItem
					bg={noBg ? "transparent" : (theme.name === "Default" ? "primary.brightGrey" : "primary.white")}
					color="primary.text"
					borderRadius="lg"
					px={["4", null, "8"]}
					mb="4"
					{...trackingAttributes}>
					{({ isExpanded }) => (
						<>
							<h3>
								<AccordionButton
									py={["4", null, "1.375rem"]}
									justifyContent="space-between"
									aria-label={isExpanded ? t("general.labels.close") : t("general.labels.open")}
									title={isExpanded ? t("general.labels.close") : t("general.labels.open")}>
									<Box textAlign="left">
										<ChakraText as="span" size="xs" flex="1" textAlign="left">{item.fields?.title?.value}</ChakraText>
										<ChakraText as="span" variant="overline" size="xs" flex="1" textAlign="left" ps="3">({assetCount} {assetCount > 1 ? t("general.labels.items") : t("general.labels.item")})</ChakraText>
									</Box>
									<Icon
										variant={isExpanded ? "Up" : "Down"}
										w="icons.lg"
										flexShrink="0"
										marginInlineEnd="1"
										color="primary.aramcoLinkBlue"
										ms={["4", null, "12"]}
									/>
								</AccordionButton>
							</h3>
							<AccordionPanel>
								{children}
								{downloadAvailable &&
									<DocumentListDownload item={item}/>
								}
							</AccordionPanel>
						</>
					)}
				</AccordionItem>
				:
				<Box
					bg={noBg ? "transparent" : (theme.name === "Default" ? "primary.brightGrey" : "primary.white")}
					color="primary.text"
					borderRadius="lg"
					px={["4", null, "8"]}
					mb="4" {...trackingAttributes}>
					<Heading as="h3" size="xs" py="1.375rem">{item.fields?.title?.value}</Heading>
					{children}
					{(downloadAvailable && !compactVersion) &&
						<DocumentListDownload item={item}/>
					}
				</Box>
			}
		</>
	);
}